@use '../../../assets/styles/colors';
.css-eg3pzz-MuiClockPointer-thumb {
  background-color: colors.$primary-blue !important;
  border: 16px solid colors.$primary-blue !important;
}
.MuiClock-pin.css-umzx0k-MuiClock-pin {
  background-color: colors.$primary-blue !important;
}
.MuiClockPointer-root.css-d0vs79-MuiClockPointer-root {
  background-color: colors.$primary-blue !important;
}
.MuiClockPointer-root.css-rdq5h4-MuiClockPointer-root {
  background-color: colors.$primary-blue !important;
}
.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: colors.$primary-blue !important;
}
.css-12t0dn4-MuiClockPointer-thumb {
  border: 16px solid colors.$primary-blue !important;
}
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: colors.$primary-blue !important;
}
