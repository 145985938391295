.custom-checkbox {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;

  .class_item__checkbox {
    transform: translateX(-8px);
  }
  .check-box_label {
    font-size: 1rem;
  }
}
