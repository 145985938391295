.modal {
  background: #ffffff;
  border-radius: 12px;
  width: 518px;
  padding: 28px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:last-child {
      cursor: pointer;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-bottom: 20px;

    p:first-child {
      text-transform: capitalize;
    }

    p {
      font-weight: 500;
      font-size: 18px;
    }

    p:last-child {
      font-weight: 400;
      font-size: 12px;
      color: #aeaeae;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: #ffffff;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      width: calc(50% - 6px);
      padding: 12.5px 0;
      cursor: pointer;
      color: #8a8a8a;
      height: 50px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #e1dede;
      }
    }

    button:last-child {
      color: white;
      background-color: #ed3863;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #f27693;
      }
    }
  }
}
