@use '../../../assets/styles/colors';

.custom-dropdown {
  position: relative;
  width: 100%;
  margin-top: 15px;

  &__label {
    text-transform: capitalize;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: block;
  }

  &__selected-option {
    height: 50px;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    z-index: 0;

    i {
      font-size: 15px;
      color: colors.$dark-blue;
    }
  }

  &__dropdown-list {
    margin-top: 8px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 2;

    li {
      cursor: pointer;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: colors.$dark;

      &:hover {
        background-color: #f2f2f2;
      }

      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .status {
        color: colors.$warning;
        margin-left: auto;
      }
    }
  }
}