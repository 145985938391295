.repeat-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 13px;
    margin-bottom: 15px;
    position: relative;

    &__label {
        font-weight: 400;
        font-size: 15px;
        color: #4D4D4D;
        white-space: nowrap;
    }

    &__reps {
        display: flex;
        align-items: center;
        padding: 0 10px;
        border: 1px solid #E8E8E8;
        border-radius: 12px;
        height: 50px;
        outline: none;

        &.error {
            color: #ED3863;
            border: 1px solid #ED3863;
        }

    }

    &__select {
        margin-top: unset;
        flex-grow: 1;
    }



    &>.error-text {
        flex-basis: 100%;
        flex-shrink: 0;
        color: #ED3863;
        position: absolute;
        bottom: -40%;
        // left: 25%;
    }


}