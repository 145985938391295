.view_title_btn {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 0 17px;
  outline: none;
  min-width: 40px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    background: darken(#ffffff, 2%);
  }
  & > .view_title_btn__icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      fill: #8a8a8a;
    }
  }
  & > .view_title_btn__label {
    color: #8a8a8a;
    font-size: 15px;
    font-weight: 500;
  }

  &.primary {
    background: #00aaf9;
    border: none;
    &:hover {
      background: darken(#00aaf9, 10%);
    }
    & > .view_title_btn__icon_container > svg {
      fill: #ffffff;
    }
    & > .view_title_btn__label {
      color: #ffffff;
    }
    &.outlined {
      background: #ffffff;
      border: 1px solid #00aaf9;
      &:hover {
        background: lighten(#00aaf9, 48%);
      }
      & > .view_title_btn__icon_container > svg {
        fill: #00aaf9;
      }
      & > .view_title_btn__label {
        color: #00aaf9;
      }
    }
  }
}
