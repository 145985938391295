@use '../../../assets/styles/colors';

.add-schedule {
  min-width: 350px;
  padding: 28px 40px;

  .toggle-session-type {
    width: 100%;
    height: 50px;
    border: 1px solid #00aaf9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 10px 0;

    &__item {
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-basis: 50%;

      &--active {
        background-color: #00aaf9;
        color: #fff;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .label {
      font-size: 20px;
      color: colors.$dark;
      font-weight: 500;
    }

    .subtitle {
      display: block;
      color: #aeaeae;
      font-size: 12px;
      font-weight: 400;
    }

    .close-btn {
      position: relative;
      cursor: pointer;
      border: 2px solid colors.$dark-blue;
      border-radius: 12px;
      width: 28px;
      height: 28px;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        top: 50%;
        background-color: colors.$dark-blue;
        margin-top: -5px;
      }

      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &::before {
        right: 50%;
        transform: translateX(50%) rotate(-45deg);
      }
    }
  }

  &__custom-iteration {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    color: colors.$primary-blue;
    width: fit-content;
    display: flex;
    cursor: pointer;
  }

  &__smart-schedule {
    @extend .add-schedule__custom-iteration;
    text-align: center;
    margin-top: 15px;
    width: 100%;
  }

  &__group-session {
    color: white;
    background: colors.$primary-blue;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    border: 1px solid colors.$primary-blue;
    border-radius: 8px;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: white;
      color: colors.$primary-blue;
    }
  }
}

.MuiDialog-paper {
  width: 530px;
}

.MuiDialog-container {
  height: auto;
}
