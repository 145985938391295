.calendar__headers__class__timezone_event {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  white-space: nowrap;

  aside {
    border: 3px solid #8a8a8a;
    height: 100%;
    border-radius: 5px;
    margin-right: 10px;
  }

  .event-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        font-weight: 400;
        font-size: 15px;
        display: flex;
        justify-items: center;
        color: #262626;
        // flex-basis: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 135px;

        span {
          width: 6px;
          height: 6px;
          display: inline-block;
          border-radius: 50%;
          margin: auto 5px;
          background-color: #8a8a8a;
        }
      }

      .level {
        font-weight: 500;
        font-size: 11px;
        display: grid;
        place-content: center;
        min-height: fit-content;
        max-width: 96px;
        height: 22px;
        padding: 0 10px;
        background: #eeedfd;
        border-radius: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #8a8a8a;
      }
    }

    .teacher {
      display: flex;
      justify-items: center;
      gap: 10px;

      .img-container {
        img {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid #e7e7e7;
          object-fit: cover;
        }
      }

      p {
        font-weight: 400;
        font-size: 13px;
        color: #575757;
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 13px;
      color: #575757;
      text-overflow: ellipsis;
      padding-right: 5px;

      p:last-of-type {
        max-width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.small {
    aside {
      border: 2px solid #8a8a8a;
    }

    .event-container {
      header {
        max-width: 90%;

        .name {
          font-size: 14px;
          width: calc(100% - 80px);
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .level {
          max-width: 80px;
          height: 20px;
          padding: 0 8px;
          flex-shrink: 0;
        }
      }

      .teacher {
        gap: 8px;

        .img-container {
          img {
            height: 18px;
            width: 18px;
          }
        }

        p {
          font-size: 12px;
        }
      }

      footer {
        flex-wrap: wrap;
        font-size: 13px;
        padding-right: 5px;

        p:last-of-type {
          max-width: 95%;
        }
      }
    }
  }
}
