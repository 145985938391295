@use '../../../../assets/styles/colors';

.current-day {
  display: flex;
  align-items: center;

  &__arrow {
    display: flex;
    align-items: center;

    svg {
      width: 18px;
      height: 20px;
      fill: #363853;
      cursor: pointer;
    }

    &--left {
      margin-right: 20px;
    }

    &--right {
      margin-left: 20px;
    }
  }

  &__toogle-calendar {
    margin-left: -10px;
    margin-right: 10px;
    cursor: pointer;
  }

  &__date {
    width: 165px;
    height: 38px;
    // border: 1px solid #e7e7e7;
    // border-radius: 8px 0 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: #363853;
    font-weight: 400;
    font-size: 15px;
    white-space: nowrap;
  }

  &__calendar {
    border: 1px solid #e7e7e7;
    border-left: none;
    border-radius: 0 8px 8px 0;
    width: 40px;
    height: 38px;
    display: grid;
    place-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #f7f7f7;
    }

    svg path {
      fill: colors.$primary-blue;
    }
  }
  &__today-btn {
    background-color: colors.$primary-blue;
    color: white;
    padding: 0.4rem;
    width: 80px;
    border: none;
    border-radius: 10px;
    margin-left: 20px;
    border: 1px solid colors.$primary-blue;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    font-size: 0.85rem;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: colors.$primary-blue;
    }
  }
}
