.main_header {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 30px;

  &>.main_header__column {
    display: flex;
    align-items: center;

    &.main_header__right_column {
      gap: 18px;
      margin-left: auto;
    }

    &.main_header__left_column {
      gap: 18px;
    }

    &>.main_header__toggle_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      transition: all 300ms ease;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      cursor: pointer;

      &>svg {
        stroke: #8a8a8a;
      }
    }

    &>.main_header__logo_container {
      display: flex;
      align-items: center;
      gap: 10px;

      &>.main_logo {
        width: 32px;
      }
    }

    &>.main_header__avatar_btn {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      &>.avatar_text {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        cursor: pointer;

        &>.avatar_text__user {
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          text-transform: capitalize;
        }

        &>.avatar_text__role {
          font-size: 14px;
          color: #91939a;
        }
      }

      &__dropdown {
        background: white;
        border-radius: 4px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 5rem;
        position: absolute;
        top: 4rem;
        right: 0;
        width: 20rem;
        z-index: 999;
      }
    }

    &>.MuiDivider-root {
      height: 35px;
    }

    &>.main_header__title_container {
      display: flex;
      align-items: center;
      gap: 3px;

      &>.main_header__title_container__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;

        &>svg {
          fill: #00aaf9;
        }
      }

      &>.main_header__title_container__title {
        font-size: 18px;
        font-weight: 500;
        color: #00aaf9;
      }
    }
  }

  &.main_header__no_border {
    border-bottom: none;
  }

  &__filters.show {
    opacity: 1;
    pointer-events: auto;
  }

  &__filters {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 10px;
    gap: 10px;
    opacity: 0;
    pointer-events: none;
    max-width: 79%;
    transition: all 0.3s ease;

    .filter-select {
      width: calc(100% / 5);
    }

    .filter-btn,
    .reset-btn {
      border-radius: 8px;
      font-size: 15px;
      font-weight: 400;
      padding: 0.3rem 1rem;
      text-transform: capitalize;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }

    .filter-btn {
      color: white;
      background-color: #00aaf9;
      border: 1px solid #00aaf9;
      height: 35px;

      &:hover {
        background-color: #00aaf9d4;
      }
    }

    .reset-btn {
      background-color: white;
      padding: 0.3rem 1rem;
      border: 1px solid #e7e7e7;
      color: #8a8a8a;

      &:hover {
        background-color: rgba(207, 204, 204, 0.184)
      }
    }
  }

}