.filters {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  height: 65%;
  overflow: auto;
  padding: 0 12px;

  &>.filters__label {
    font-size: 14px;
    color: #8a8a8a;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  &>.filters__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 21px;
    background-color: #fff;
    z-index: 1;

    &>.label {
      font-size: 18px;
      font-weight: 500;
      color: #434343;
      margin-right: auto;
    }

    &>.icon_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      cursor: pointer;

      &>svg {
        stroke: #363853;
        transition: all 300ms ease;

        &.hidden {
          transform: rotate(180deg);
        }
      }
    }
  }

  &>.filters__body {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 8px;
  }
}